import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { saasModernTheme } from '../../../common/src/theme/saasModern';
import SEO from '../components/seo';
import { ResetCSS } from '../../../common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../containers/sassModern.style';
import { DrawerProvider } from '../../../common/src/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import BannerSectionSmall from '../containers/BannerSmall';
import Footer from '../containers/Footer';
import MoreBlogPosts from '../containers/MoreBlogPosts';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const BlogContent = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }

  p code {
    margin-left: 5px;
    margin-right: 5px;
  }

  pre {
    background: #f3f3f3;
    white-space: pre;
    overflow: auto;
    padding: 20px 25px;
    border-radius: 4px;
  }
`;

const Author = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  text-align: right;
  color: #343d48cc;
`;

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) {
  const { t } = useTranslation();
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={frontmatter.title}
          lang={pageContext.lang}
          description={frontmatter.description}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={frontmatter.title}
            descriptionText={frontmatter.description}
            image={
              markdownRemark.frontmatter.featuredImage
                ? markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
                : null
            }
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <BlogContent dangerouslySetInnerHTML={{ __html: html }} />
              <Author>
                {t('written_by_on', {
                  author: frontmatter.author,
                  date: frontmatter.date,
                })}
              </Author>
            </Container>
          </Box>

          <MoreBlogPosts pageContext={pageContext} />
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}

export const pageQuery = graphql`
  query($slug: String!, $lang: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug }, lang: { eq: $lang } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1170, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
